import React from 'react'
import { stringifyInput } from '../../../helpers/StringHelper'

interface Props {
  readonly isLoading?: boolean
  readonly type?: 'submit' | 'button'
}

const Button = React.forwardRef<HTMLButtonElement, Props & React.HTMLProps<HTMLButtonElement>>(
  ({ className, onClick, disabled, isLoading, type, children, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        type={type || 'button'}
        disabled={isLoading || disabled}
        onClick={onClick}
        className={`button ${stringifyInput(className)}`}
        {...rest}
      >
        {isLoading && (
          <>
            <i className='fas fa-spinner-third fa-spin i-left' /> Processing...
          </>
        )}
        {!isLoading && <>{children}</>}
      </button>
    )
  }
)

export default Button
